//import {createContext} from "react";

export const GroupKeys = {
    Name: "Name",
    Enchants: "Enchants",
    Gems: "Gems",
    Elixirs: "Elixirs",
    Deaths: "Deaths",
    ProtPotions: "ProtPotions",
    Consumes: "Consumes",
    Abilities: "Abilities",
    Damage: "Damage",
    DispelsInterrupts: "DispelsInterrupts",
    Cooldowns: "Cooldowns",
    Tank: "Tank",
    Healer: "Healer",
    Dps: "Dps",
    Resistance: "Resistance",
    Mage: "Mage",
}

export const DataPoints = {
    Name: "Name",
    Type: "Type",
    Enchants: "Enchants",
    EnchantGood: "EnchantGood",
    EnchantAverage: "EnchantAverage",
    EnchantBad: "EnchantBad",
    EnchantMissing: "EnchantMissing",
    EnchantList: "EnchantList",
    GemsMissing: "GemsMissing",
    GemsCommon: "GemsCommon",
    GemsUncommon: "GemUncommon",
    GemsRare: "GemsRare",
    GemsEpic: "GemsEpic",
    GemsList: "GemsList",
    Elixirs: "Elixirs",
    ElixirsFlasks: "ElixirsFlasks",
    ElixirsGuardian: "ElixirsGuardian",
    ElixirsBattle: "ElixirsBattle",
    ElixirsFood: "ElixirsFood",
    ElixirsSeasonal: "ElixirsSeasonal",
    ElixirsScrolls: "ElixirsScrolls",
    ElixirsWeaponEnchants: "ElixirsWeaponEnchants",
    Deaths: "Deaths",
    ProtectionPotionsTotal: "ProtectionPotionsTotal",
    ProtectionPotionsTotalAbsorbed: "ProtectionPotionsTotalAbsorbed",
    ProtectionPotionsNature: "ProtectionPotionsNature",
    ProtectionPotionsGreaterNature: "ProtectionPotionsGreaterNature",
    ProtectionPotionsMajorNature: "ProtectionPotionsMajorNature",
    ProtectionPotionsNatureAbsorbed: "ProtectionPotionsNatureAbsorbed",
    ProtectionPotionsFire: "ProtectionPotionsFire",
    ProtectionPotionsGreaterFire: "ProtectionPotionsGreaterFire",
    ProtectionPotionsMajorFire: "ProtectionPotionsMajorFire",
    ProtectionPotionsFireAbsorbed: "ProtectionPotionsFireAbsorbed",
    ProtectionPotionsFrost: "ProtectionPotionsFrost",
    ProtectionPotionsGreaterFrost: "ProtectionPotionsGreaterFrost",
    ProtectionPotionsMajorFrost: "ProtectionPotionsMajorFrost",
    ProtectionPotionsFrostAbsorbed: "ProtectionPotionsFrostAbsorbed",
    ProtectionPotionsShadow: "ProtectionPotionsShadow",
    ProtectionPotionsGreaterShadow: "ProtectionPotionsGreaterShadow",
    ProtectionPotionsMajorShadow: "ProtectionPotionsMajorShadow",
    ProtectionPotionsShadowAbsorbed: "ProtectionPotionsShadowAbsorbed",
    ProtectionPotionsGreaterArcane: "ProtectionPotionsGreaterArcane",
    ProtectionPotionsMajorArcane: "ProtectionPotionsMajorArcane",
    ProtectionPotionsArcaneAbsorbed: "ProtectionPotionsArcaneAbsorbed",
    ConsumesPotions: "ConsumesPotions",
    ConsumesDrums: "ConsumesDrums",
    ConsumesGems: "ConsumesGems",
    ConsumesExplosives: "ConsumesExplosives",
    ConsumesBandages: "ConsumesBandages",
    ConsumesManaPots: "ConsumesManaPots",
    ConsumesRunes: "ConsumesRunes",
    ConsumesRejuvPots: "ConsumesRejuvPots",
    ConsumesHealthPots: "ConsumesHealthPots",
    ConsumesHealthStones: "ConsumesHealthStones",
    ConsumesWhipperRootTuber: "ConsumesWhipperRootTuber",
    ConsumesRagePotions: "ConsumesRagePotions",
    ConsumesThistleTea: "ConsumesThistleTea",
    ConsumesRestorationPots: "ConsumesRestorationPots",
    ConsumesFreeActionPotion: "ConsumesFreeActionPotion",
    ConsumesLivingActionPotion: "ConsumesLivingActionPotion",
    ConsumesManaGem: "ConsumesManaGem",
    ConsumesNightmareSeed: "ConsumesNightmareSeed",
    ConsumesStoneshield: "ConsumesStoneshield",
    ConsumesHeavyRuneclothBandage: "ConsumesHeavyRuneclothBandage",
    ConsumesAntiVenom: "ConsumesAntiVenom",
    ConsumesHolyWater: "ConsumesHolyWater",
    ConsumesSapperCharge: "ConsumesSapperCharge",
    ConsumesSuperSapperCharge: "ConsumesSuperSapperCharge",
    ConsumesGlobalThermalSapperCharge: "ConsumesGlobalThermalSapperCharge",
    ConsumesEzThro: "ConsumesEzThro",
    ConsumesDenseDynamite: "ConsumesDenseDynamite",
    ConsumesFelIronBombs: "ConsumesFelIronBombs",
    ConsumesArcaneBombs: "ConsumesArcaneBombs",
    ConsumesDrumsBattle: "ConsumesDrumsBattle",
    ConsumesDrumsRestoration: "ConsumesDrumsRestoration",
    ConsumesDrumsPanic: "ConsumesDrumsPanic",
    ConsumesDrumsWar: "ConsumesDrumsWar",
    ConsumesInsaneStrength: "ConsumesInsaneStrength",
    ConsumesShrouding: "ConsumesShrouding",
    ConsumesFelRegeneration: "ConsumesFelRegeneration",
    ConsumesHeroic: "ConsumesHeroic",
    ConsumesDestruction: "ConsumesDestruction",
    ConsumesHaste: "ConsumesHaste",
    ConsumesSpeed: "ConsumesSpeed",
    ConsumesFelMana: "ConsumesFelMana",
    ConsumesGreaterStoneshield: "ConsumesGreaterStoneshield",
    ConsumesIronshield: "ConsumesIronshield",
    ConsumesWildMagic: "ConsumesWildMagic",
    ConsumesIndestructable: "ConsumesIndestructable",
    DispelTotal: "DispelTotal",
    DispelBoss: "DispelBoss",
    DispelTrash: "DispelTrash",
    DispelPoison: "DispelPoison",
    DispelDisease: "DispelDisease",
    DispelCurse: "DispelCurse",
    DispelMagic: "DispelMagic",
    DispelFrenzy: "DispelFrenzy",
    DispelDruidCurePoison: "DispelDruidCurePoison",
    DispelDruidAbolishPoison: "DispelDruidAbolishPoison",
    DispelDruidRemoveCurse: "DispelDruidRemoveCurse",
    DispelHunterTranqShot: "DispelHunterTranqShot",
    DispelMageRemoveLesserCurse: "DispelMageRemoveLesserCurse",
    DispelPaladinPurify: "DispelPaladinPurify",
    DispelPaladinCleanse: "DispelPaladinCleanse",
    DispelPriestDispelMagic: "DispelPriestDispelMagic",
    DispelPriestCureDisease: "DispelPriestCureDisease",
    DispelPriestAbolishDisease: "DispelPriestAbolishDisease",
    DispelShamanPurge: "DispelShamanPurge",
    DispelShamanCureToxins: "DispelShamanCureToxins",
    DispelShamanCleanseSpirit: "DispelShamanCleanseSpirit",
    DispelShamanCleansingTotem: "DispelShamanDiseaseCleansingTotem",
    DispelWarlockDevourMagic: "DispelWarlockDevourMagic",
    InterruptTotal: "InterruptTotal",
    InterruptDruidBash: "InterruptDruidBash",
    InterruptDruidFeralCharge: "InterruptDruidFeralCharge",
    InterruptDruidMaim: "InterruptDruidMaim",
    InterruptMageCounterspell: "InterruptMageCounterspell",
    InterruptPaladinHammerOfJustice: "InterruptPaladinHammerOfJustice",
    InterruptPriestSilence: "InterruptPriestSilence",
    InterruptRogueKick: "InterruptRogueKick",
    InterruptShamanEarthShock: "InterruptShamanEarthShock",
    InterruptWarriorPummel: "InterruptWarriorPummel",
    InterruptCheapShot: "InterruptCheapShot",
    InterruptWarlockSpellLock: "InterruptWarlockSpellLock",
    InterruptHunterIntimidate: "InterruptHunterIntimidate",
    InterruptWarstomp: "InterruptWarstomp",
    InterruptMindFreeze: "InterruptMindFreeze",
    InterruptStrangulate: "InterruptStrangulate",
    Cooldowns: "Cooldowns",
    CooldownsRacial: "CooldownsRacial",
    CooldownsAbility: "CooldownsAbility",
    CooldownsItems: "CooldownsItems",
    DamageTakenHit: "DamageTakenHit",
    DamageTakenCrit: "DamageTakenCrit",
    DamageTakenCrushed: "DamageTakenCrushed",
    DamageTakenBlocked: "DamageTakenBlocked",
    DamageTakenMiss: "DamageTakenMiss",
    DamageTakenDodge: "DamageTakenDodge",
    DamageTakenParry: "DamageTakenParry",
    ResistanceNature: "ResistanceNature",
    ResistanceFire: "ResistanceFire",
    ResistanceFrost: "ResistanceFrost",
    ResistanceShadow: "ResistanceShadow",
    ResistanceArcane: "ResistanceArcane",
    MageArcaneBlast: "MageArcaneBlast",
    MageArcaneMissiles: "MageArcaneMissiles",
    MageArcaneBarrage: "MageArcaneBarrage",
    MageArcaneExplosion: "MageArcaneExplosion",
    MageBlizzard: "MageBlizzard",
    MageFireBall: "MageFireBall",
    MageFireBlast: "MageFireBlast",
    MageFlamestrike: "MageFlamestrike",
    MageManaShield: "MageManaShield",
    MageFrostWard: "MageFrostWard",
    MageMageArmor: "MageMageArmor",
    MageFireWard: "MageFireWard",
    MageIceArmor: "MageIceArmor",
    MageMoltenArmor: "MageMoltenArmor",
    MageInvisibility: "MageInvisibility",
    MageLivingBomb: "MageLivingBomb",
    MagePyroblast: "MagePyroBlast",
    MageBlastWave: "MageBlastWave",
    MageDragonsBreath: "MageDragonsBreath",
    MageFrostbolt: "MageFrostBolt",
    MageDeepFreeze: "MageDeepFreeze",
    MageFrostfireBolt: "MageFrostfireBolt",
};

export const emptyData = {
    [DataPoints.Name]: " ",
    [DataPoints.Type]: " ",
    [DataPoints.Enchants]: " ",
    [DataPoints.EnchantGood]: " ",
    [DataPoints.EnchantAverage]: " ",
    [DataPoints.EnchantBad]: " ",
    [DataPoints.EnchantMissing]: " ",
    [DataPoints.EnchantList]: " ",
    [DataPoints.GemsMissing]: " ",
    [DataPoints.GemsCommon]: " ",
    [DataPoints.GemsUncommon]: " ",
    [DataPoints.GemsRare]: " ",
    [DataPoints.GemsEpic]: " ",
    [DataPoints.GemsList]: [],
    [DataPoints.Elixirs]: " ",
    [DataPoints.ElixirsFlasks]: [],
    [DataPoints.ElixirsGuardian]: [],
    [DataPoints.ElixirsBattle]: [],
    [DataPoints.ElixirsFood]: [],
    [DataPoints.ElixirsSeasonal]: [],
    [DataPoints.ElixirsScrolls]: [],
    [DataPoints.ElixirsWeaponEnchants]: [],
    [DataPoints.Deaths]: " ",
    [DataPoints.ProtectionPotionsTotal]: " ",
    [DataPoints.ProtectionPotionsTotalAbsorbed]: " ",
    [DataPoints.ProtectionPotionsNature]: " ",
    [DataPoints.ProtectionPotionsGreaterNature]: " ",
    [DataPoints.ProtectionPotionsMajorNature]: " ",
    [DataPoints.ProtectionPotionsNatureAbsorbed]: " ",
    [DataPoints.ProtectionPotionsFire]: " ",
    [DataPoints.ProtectionPotionsGreaterFire]: " ",
    [DataPoints.ProtectionPotionsMajorFire]: " ",
    [DataPoints.ProtectionPotionsFireAbsorbed]: " ",
    [DataPoints.ProtectionPotionsFrost]: " ",
    [DataPoints.ProtectionPotionsGreaterFrost]: " ",
    [DataPoints.ProtectionPotionsMajorFrost]: " ",
    [DataPoints.ProtectionPotionsFrostAbsorbed]: " ",
    [DataPoints.ProtectionPotionsShadow]: " ",
    [DataPoints.ProtectionPotionsGreaterShadow]: " ",
    [DataPoints.ProtectionPotionsMajorShadow]: " ",
    [DataPoints.ProtectionPotionsShadowAbsorbed]: " ",
    [DataPoints.ProtectionPotionsGreaterArcane]: " ",
    [DataPoints.ProtectionPotionsMajorArcane]: " ",
    [DataPoints.ProtectionPotionsArcaneAbsorbed]: " ",
    [DataPoints.ConsumesPotions]: " ",
    [DataPoints.ConsumesDrums]: " ",
    [DataPoints.ConsumesGems]: " ",
    [DataPoints.ConsumesExplosives]: " ",
    [DataPoints.ConsumesBandages]: " ",
    [DataPoints.ConsumesManaPots]: " ",
    [DataPoints.ConsumesRunes]: " ",
    [DataPoints.ConsumesRejuvPots]: " ",
    [DataPoints.ConsumesHealthPots]: " ",
    [DataPoints.ConsumesHealthStones]: " ",
    [DataPoints.ConsumesRagePotions]: " ",
    [DataPoints.ConsumesThistleTea]: " ",
    [DataPoints.ConsumesRestorationPots]: " ",
    [DataPoints.ConsumesElixirOfPoisonResistance]: " ",
    [DataPoints.ConsumesFreeActionPotion]: " ",
    [DataPoints.ConsumesManaGem]: " ",
    [DataPoints.ConsumesNightmareSeed]: " ",
    [DataPoints.ConsumesStoneshield]: " ",
    [DataPoints.ConsumesOilOfImmolation]: " ",
    [DataPoints.ConsumesHeavyRuneclothBandage]: " ",
    [DataPoints.ConsumesAntiVenom]: " ",
    [DataPoints.ConsumesHolyWater]: " ",
    [DataPoints.ConsumesSapperCharge]: " ",
    [DataPoints.ConsumesSuperSapperCharge]: " ",
    [DataPoints.ConsumesGlobalThermalSapperCharge]: " ",
    [DataPoints.ConsumesEzThro]: " ",
    [DataPoints.ConsumesFelIronBombs]: " ",
    [DataPoints.ConsumesArcaneBombs]: " ",
    [DataPoints.ConsumesDenseDynamite]: " ",
    [DataPoints.ConsumesDrumsBattle]: " ",
    [DataPoints.ConsumesDrumsRestoration]: " ",
    [DataPoints.ConsumesDrumsPanic]: " ",
    [DataPoints.ConsumesDrumsWar]: " ",
    [DataPoints.ConsumesInsaneStrength]: " ",
    [DataPoints.ConsumesShrouding]: " ",
    [DataPoints.ConsumesFelRegeneration]: " ",
    [DataPoints.ConsumesHeroic]: " ",
    [DataPoints.ConsumesDestruction]: " ",
    [DataPoints.ConsumesHaste]: " ",
    [DataPoints.ConsumesSpeed]: " ",
    [DataPoints.ConsumesFelMana]: " ",
    [DataPoints.ConsumesIronshield]: " ",
    [DataPoints.ConsumesWildMagic]: " ",
    [DataPoints.ConsumesIndestructable]: " ",
    [DataPoints.DispelTotal]: " ",
    [DataPoints.DispelBoss]: " ",
    [DataPoints.DispelTrash]: " ",
    [DataPoints.DispelPoison]: " ",
    [DataPoints.DispelDisease]: " ",
    [DataPoints.DispelCurse]: " ",
    [DataPoints.DispelMagic]: " ",
    [DataPoints.DispelFrenzy]: " ",
    [DataPoints.DispelDruidCurePoison]: " ",
    [DataPoints.DispelDruidAbolishPoison]: " ",
    [DataPoints.DispelDruidRemoveCurse]: " ",
    [DataPoints.DispelHunterTranqShot]: " ",
    [DataPoints.DispelMageRemoveLesserCurse]: " ",
    [DataPoints.DispelPaladinPurify]: " ",
    [DataPoints.DispelPaladinCleanse]: " ",
    [DataPoints.DispelPriestDispelMagic]: " ",
    [DataPoints.DispelPriestCureDisease]: " ",
    [DataPoints.DispelPriestAbolishDisease]: " ",
    [DataPoints.DispelShamanPurge]: " ",
    [DataPoints.DispelShamanCureToxins]: " ",
    [DataPoints.DispelShamanCleanseSpirit]: " ",
    [DataPoints.DispelShamanCleansingTotem]: " ",
    [DataPoints.DispelWarlockDevourMagic]: " ",
    [DataPoints.InterruptTotal]: " ",
    [DataPoints.InterruptDruidBash]: " ",
    [DataPoints.InterruptDruidFeralCharge]: " ",
    [DataPoints.InterruptDruidMaim]: " ",
    [DataPoints.InterruptMageCounterspell]: " ",
    [DataPoints.InterruptPaladinHammerOfJustice]: " ",
    [DataPoints.InterruptPriestSilence]: " ",
    [DataPoints.InterruptRogueKick]: " ",
    [DataPoints.InterruptShamanEarthShock]: " ",
    [DataPoints.InterruptWarriorPummel]: " ",
    [DataPoints.InterruptCheapShot]: " ",
    [DataPoints.InterruptWarlockSpellLock]: " ",
    [DataPoints.InterruptHunterIntimidate]: " ",
    [DataPoints.InterruptWarstomp]: " ",
    [DataPoints.InterruptMindFreeze]: " ",
    [DataPoints.InterruptStrangulate]: " ",
    [DataPoints.Cooldowns]: [],
    [DataPoints.CooldownsRacial]: [],
    [DataPoints.CooldownsAbility]: [],
    [DataPoints.CooldownsItems]: [],
    [DataPoints.DamageTakenHit]: " ",
    [DataPoints.DamageTakenCrushed]: " ",
    [DataPoints.DamageTakenCrit]: " ",
    [DataPoints.DamageTakenBlocked]: " ",
    [DataPoints.DamageTakenParry]: " ",
    [DataPoints.DamageTakenDodge]: " ",
    [DataPoints.DamageTakenMiss]: " ",
    [DataPoints.ResistanceArcane]: " ",
    [DataPoints.ResistanceFire]: " ",
    [DataPoints.ResistanceFrost]: " ",
    [DataPoints.ResistanceNature]: " ",
    [DataPoints.ResistanceShadow]: " ",
    [DataPoints.MageArcaneBlast]: " ",
    [DataPoints.MageArcaneMissiles]: " ",
    [DataPoints.MageArcaneBarrage]: " ",
    [DataPoints.MageArcaneExplosion]: " ",
    [DataPoints.MageBlizzard]: " ",
    [DataPoints.MageFireBlast]: " ",
    [DataPoints.MageFlamestrike]: " ",
    [DataPoints.MageManaShield]: " ",
    [DataPoints.MageFrostWard]: " ",
    [DataPoints.MageMageArmor]: " ",
    [DataPoints.MageFireWard]: " ",
    [DataPoints.MageIceArmor]: " ",
    [DataPoints.MageMoltenArmor]: " ",
    [DataPoints.MageInvisibility]: " ",
    [DataPoints.MageFireBall]: " ",
    [DataPoints.MageLivingBomb]: " ",
    [DataPoints.MagePyroblast]: " ",
    [DataPoints.MageBlastWave]: " ",
    [DataPoints.MageDragonsBreath]: " ",
    [DataPoints.MageFrostbolt]: " ",
    [DataPoints.MageDeepFreeze]: " ",
    [DataPoints.MageFrostfireBolt]: " ",
}
